<template>
    <AddReservation />
    <div class="reservation-table">
        <div class="top">
            <Logo />
            <TopTab>
                <router-link
                    class="btn-solid"
                    :to="`/reservation_table/${
                        isShowDaily ? 'single_aroma' : 'multi_aroma'
                    }`"
                    @click="updateIsShowDaily"
                >
                    <span v-if="isShowDaily">月曆預約</span>
                    <span v-else>日曆預約</span>
                </router-link>
            </TopTab>
        </div>
        <div class="index-container">
            <div class="title">預約表</div>

            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

import Logo from "@/components/includes/Logo";
import AddReservation from "@/components/lightbox/addReservation/AddReservation";
import TopTab from "@/components/includes/TopTab.vue";

export default {
    name: "ReservationTable",
    components: {
        Logo,
        AddReservation,
        TopTab
    },
    setup() {
        const { state, getters, commit } = useStore();
        const isShowDaily = computed(
            () => getters["reservationTable/getIsShowDaily"]
        );
        const viewStatus = computed(() => state.reservationTable.viewStatus);


        const updateIsShowDaily = () => {
            commit("reservationTable/update", {
                key: "viewStatus",
                value: viewStatus === "daily" ? "monthly" : "daily",
            });
        };

        return { isShowDaily, updateIsShowDaily };
    },
};
</script>

<style lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";
.reservation-table {
    position: relative;

    .btn-solid {
        display: block;
        padding: 11px 30px;
        border: 1px solid #fff;
        border-radius: 22px;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        background: linear-gradient(
            110deg,
            rgba(171, 126, 43, 1),
            rgba(241, 227, 181, 1),
            rgba(171, 126, 43, 1),
            rgba(196, 129, 9, 1)
        );
        box-sizing: border-box;
    }

    .index-container {
        .title {
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
            color: #fff;
            margin-left: 50px;
            margin-bottom: 30px;
            position: relative;
            &::before {
                position: absolute;
                content: "";
                top: 0;
                bottom: 0;
                left: -15px;
                border-left: 7px solid #fff;
            }
            @include phone {
                margin-bottom: 20px;
            }
        }
    }

    .content-panel {
        border: 1px solid #fff;
        border-radius: 15px;
        box-shadow: 0 34px 77px 0 rgba(0, 0, 0, 0.26);
        background: linear-gradient(143deg, #fff, #f1eeee, #fff);
    }

    .add-room {
        width: unset;
        @include pad_pro_lg {
            right: 30px;
            bottom: 30px;
        }
        @include pad {
            right: 20px;
            bottom: 20px;
        }
        @include phone {
            right: 15px;
            bottom: 15px;
        }
        .img-wrap {
            background: linear-gradient(180deg, #ebc496, #a4641a, #a4641a) 0% 0%
                no-repeat padding-box;
            box-shadow: 0px 18px 31px #45425359;
            border: 2px solid #ffffffd1;
            width: 54px;
            height: 54px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            @include pad_pro_lg {
                width: 50px;
                height: 53px;
            }
            @include pad {
                width: 45px;
                height: 45px;
            }
            @include phone {
                width: 40px;
                height: 40px;
            }
        }
    }
}
</style>
