<template>
    <div>
        <div
            class="mask"
            v-show="isShow"
            @click="handleCloseModal(resetForm, false), CloseTab()"
        ></div>
        <transition name="slide">
            <div class="add-reservation-lightbox" v-show="isShow">
                <div class="side-menu-wrap">
                    <form class="side-menu" @submit="handleReservation">
                        <div
                            class="side-menu-close"
                            @click="handleCloseModal(resetForm, false), CloseTab()"
                        >
                            <img src="@/assets/tgd/lightbox/close.svg" alt="" />
                        </div>
                        <div class="side-menu-top">
                            <div class="side-menu-item">
                                <div class="title mb-3 pt-3">購買會員：</div>
                                <div class="row form">
                                    <div class="col-5">
                                        <BaseInput
                                            title="會員電話"
                                            placeholder="請輸入"
                                            type="tel"
                                            class="form-control"
                                            name="customer_phone"
                                            @inputBlurred="
                                                handleMemberPhoneByBlur
                                            "
                                        />
                                    </div>
                                    <div class="col-7">
                                        <BaseInput
                                            title="會員名稱"
                                            placeholder="請輸入"
                                            type="text"
                                            class="form-control"
                                            name="name"
                                        />
                                    </div>
                                    <div class="col-5">
                                        <BaseDatepicker
                                            label="預約日期"
                                            name="reserve_date"
                                            initVal=""
                                        />
                                    </div>
                                    <div class="col-7">
                                        <BaseDatepicker
                                            label="預約時間"
                                            name="reserve_time"
                                            :timePicker="true"
                                            format="HH:mm"
                                            initVal=""
                                        />
                                    </div>
                                    <div class="col-5">
                                        <BaseInput
                                            title="預約櫃台"
                                            placeholder="請輸入"
                                            type="text"
                                            class="form-control"
                                            name="counter"
                                            disabled
                                        />
                                    </div>
                                    <div class="col-7">
                                        <BaseInput
                                            title="預約人數"
                                            placeholder="請輸入"
                                            inputClass="form-control"
                                            :replaceBySlot="true"
                                        >
                                            <BaseCounter2
                                                name="reserve_people"
                                                class="counter-bg ps-4"
                                                :maxAmount="10"
                                            />
                                        </BaseInput>
                                    </div>
                                    <div class="col-12">
                                        <BaseTextarea
                                            name="remark"
                                            placeholder="請輸入備註"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="side-menu-bottom">
                            <template v-if="values.reserve_people">
                                <ReservationItem
                                    v-for="(customer, index) in values.demand"
                                    :key="customer.id"
                                    :index="index"
                                    :customer="customer"
                                    :initOptions="initOptions"
                                    :values="values"
                                    :rootStyle="{
                                        position: 'relative',
                                        'z-index': 100 - index,
                                    }"
                                    :errorMsg="
                                        errors[`demand[${index}].service`]
                                    "
                                    :isEditSet="isEditSet"
                                />
                            </template>
                            <div
                                class="side-menu-item"
                                v-show="!isAlreadyRegistered"
                            >
                                <div class="row">
                                    <div class="col-4 col-4-less">
                                        <div class="input-group">
                                            <button
                                                type="button"
                                                class="btn btn-block btn-cancel"
                                                @click="
                                                    handleCloseModal(
                                                        resetForm,
                                                        true
                                                    )
                                                "
                                            >
                                                取消預約
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-4 col-4-more">
                                        <div class="input-group">
                                            <button
                                                class="
                                                    btn btn-block btn-checkout
                                                "
                                                :disabled="isSubmitting"
                                                @click="isChangePage = true"
                                            >
                                                確認預約(本日預約)
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="input-group">
                                            <button
                                                class="
                                                    btn btn-block btn-checkout
                                                "
                                                :disabled="isSubmitting"
                                                @click="isChangePage = false"
                                            >
                                                確認預約(預約表)
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <MemberSideLightbox 
                    v-if="
                        (isEditSet && Object.keys(editCustomerData).length) ||
                        (!isEditSet && Object.keys(customerData.data).length)
                    "
                    :customerData="isEditSet ? editCustomerData : customerData.data"
                    :values="values"
                    :orderLocation="orderLocation" 
                    :sourceTag="lightboxInfo.source_tag"
                    :remarkTag="lightboxInfo.remark_tag"
                    sourceName="customer_source"
                    remarkName="customer_remark"
                    ref="memberSide"
                    :canChangeOrderLocation="false"
                />
            </div>
        </transition>
    </div>
</template>

<script>
import { ref, reactive, computed, watch } from "vue";
import { useStore } from "vuex";
import { useForm, useFieldArray } from "vee-validate";
import * as yup from "yup";
import moment from "moment";
import { basePost } from "@/js/services/baseService";
import ReservationItem from "@/components/lightbox/addReservation/ReservationItem.vue";

import useCheckMemberPhone from "@/hooks/reservation/useCheckMemberPhone";
import useHandleCloseModal from "@/hooks/reservation/useHandleCloseModal";
import useHandleUpdate from "@/hooks/reservation/useHandleUpdate";
import useEditReserveNum from "@/hooks/reservation/useEditReserveNum";
import useHandleReservation from "@/hooks/reservation/useHandleReservation";
import SwalCustom from "@/hooks/common/useSwalCustom";
import MemberSideLightbox from '@/components/lightbox/MemberSideLightbox.vue';
import useOrderLocation from "@/hooks/common/useOrderLocation";

export default {
    name: "AddReservation",
    components: { ReservationItem, MemberSideLightbox },
    setup() {
        const { state, getters, dispatch, commit } = useStore();
        const isShow = computed(() => state.global.isAddReservationShow);
        // 當前點擊要編輯的預約id
        const selectedEventId = computed(
            () => state.reservationTable.selectedEventId
        );
        // 是否為編輯
        const isEditSet = computed(() => {
            return selectedEventId.value ? true : false;
        });
        // 是否為預約服務過的預約
        const isAlreadyRegistered = computed(() => {
            return (
                isEditSet.value &&
                state.reservationTable.selectedReserveStatus === 3
            );
        });
        const customSwal = new SwalCustom({
            title: "錯誤!",
            text: "",
            icon: "error",
            confirmButtonText: "確定",
        });
        // form驗證設定
        const validationSchema = yup.object({
            customer_phone: yup.string().trim('不可留空').matches(/^09[0-9]{8}$/, '手機格式不正確').required("必填"),
            name: yup.string().required("必填"),
            reserve_people: yup.number().min(1, "至少須預約一位"),
            reserve_date: yup.string().required("必填"),
            demand: yup.array().of(
                yup
                    .object({
                        member_id: yup.mixed(),
                        room_id: yup.string().nullable(),
                        room_bed_id: yup.string().nullable(),
                        service: yup.array().of(
                            yup.object({
                                service_id: yup
                                    .mixed()
                                    .requireStringOrNumber("必填"),
                                type: yup.mixed(),
                                txt: yup.mixed(),
                            })
                        ),
                    })
                    .strict()
            ),
        });
        const {
            values,
            handleSubmit,
            isSubmitting,
            resetForm,
            setValues,
            setFieldValue,
            errors,
        } = useForm({
            validateOnMount: false,
            validationSchema,
            initialValues: {
                counter: state.auth.member_name,
            },
        });
        const { handleCloseModal } = useHandleCloseModal();
        // option初始值
        const initOptions = reactive({
            aromaList: computed(() => {
                return getters["reservationTable/getAromaList"];
            }),
            roomList: computed(() => {
                return getters["reservationTable/getRoomList"];
            }),
            serviceList: computed(() => {
                return getters["reservationTable/getServiceList"];
            }),
            paymentList: [
                { id: 1, name: "用券" },
                { id: 2, name: "現金" },
            ],
            externalList: [],
            demand: computed(() => {
                return getters["reservationTable/getDemand"];
            })
        });

        // 取得外部券清單
        const getExternalList= async () => {
            const res = await basePost("/api_external/external_list", {
                store_id: state.auth.store_id,
            });
            const { status, data } = res;

            if (status === 200 && data.res_code === 1) {
                const { res_data } = data;
                initOptions.externalList = res_data;
            }
        };
        getExternalList()

        const memberSide = ref();
        const CloseTab = () => {
            if(!memberSide.value) return;
            memberSide.value.CloseTab();
        }

        // 當前點擊要編輯的預約id
        const lightboxInfo = computed(
            () => state.reservationTable.lightboxInfo
        );

        const editCustomerData = computed(() => {
            return getters["reservationTable/getCustomerData"] || {};
        })

        watch(editCustomerData, (editCustomerData)=>{
            if(editCustomerData.customer_id) {
                orderLocation.data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].reduce((prev, curr) => {
                    return {
                        ...prev,
                        [curr]: editCustomerData.order_location[curr] || []
                    }
                },{});
                values.customer_source = editCustomerData.source;
                values.customer_remark = editCustomerData.remark;
            }
        })

        const orderId = computed(()=>{
            return state.reservationTable.selectedEventId
        });

        // 加強部位
        const { orderLocation  } = useOrderLocation(orderId);
        const { handleMemberPhoneByBlur , customerData } = useCheckMemberPhone(values, orderLocation);

        watch(isShow,(isShow) => {
            if(isShow){
                orderLocationChangeNum.value = 0;
            }else{
                customerData.data = {}
            }
        })

        // 新增不需要存加強部位
        const orderLocationChangeNum = ref(0);
        const saveLocation = () =>{}

        // 表單送出
        const { handleReservation , isChangePage } = useHandleReservation(
            {
                handleSubmit,
                customSwal,
                handleCloseModal,
                resetForm,
                selectedEventId,
                orderLocationChangeNum,
                saveLocation
            }
        );

        // 新增減少客戶
        const { push, remove, fields, replace } = useFieldArray("demand");
        useEditReserveNum({ values, isEditSet, push, remove, fields });

        // 更新資料(編輯)
        // 選取已有的預約執行
        useHandleUpdate({
            selectedEventId,
            setValues,
        });

        watch(isShow, (status) => {
            // 關閉時重製demand
            if(!status) replace([]);
            // 若為新增預設就給予當前的時間
            if (!status || isEditSet.value) return;
            setFieldValue("reserve_date", moment().format("YYYY-MM-DD"));
            setFieldValue("reserve_time", moment().format("HH:mm"));
        });

        // 切換日期需要再重新取得房間及服務資訊
        watch(
            () => values.reserve_date,
            (date) => {
                if(!isShow.value) {
                    // 切換不同編輯的時候芳療師不會被清空
                    commit("reservationTable/resetDemand");
                    return;
                };
                dispatch("reservationTable/getLightboxInfo", {
                    store_id: state.auth.store_id,
                    reserve_id: state.reservationTable.selectedEventId,
                    date,
                    time: values.reserve_time
                });
            }
        );

        return {
            isShow,
            values,
            handleReservation,
            isSubmitting,
            handleMemberPhoneByBlur,
            initOptions,
            handleCloseModal,
            resetForm,
            errors,
            isAlreadyRegistered,
            isEditSet,
            isChangePage,
            lightboxInfo,
            customerData,
            editCustomerData,
            orderLocation,
            CloseTab,
            memberSide
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";
.slide-enter-active {
    transition: all ease-in 0.3s;
}

.slide-leave-active {
    transition: all ease-in 0.3s;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateX(100%);
}
$side-menu-width: 640px;
$tab-width: 120px;
$tab-menu-width: 895px;
.add-reservation-lightbox {
    position: fixed;
    top: 0;
    right: 0;
    width: $side-menu-width;
    min-height: 100%;
    transition: ease-in 0.4s;
    z-index: 4;
    background: transparent
        linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #fff 100%) 0% 0%
        no-repeat padding-box;
    @include phone {
        width: 100%;
    }
    .side-menu-wrap {
        overflow: overlay;
        height: 100vh;
        position: relative;
        &::-webkit-scrollbar-track {
            background-color: #d6d6d6;
            border-radius: 2px;
        }
        &::-webkit-scrollbar {
            width: 7px;
        }
        &::-webkit-scrollbar-thumb {
            background: #707070;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 9px;
        }
        .side-menu {
            box-shadow: -3px -4px 41px #2e2e2e5e;
            position: absolute;
            z-index: 3;
            height: 100%;
            background: transparent
                linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #fff 100%) 0%
                0% no-repeat padding-box;
            @include phone {
                box-shadow: unset;
            }
        }
        .col-5 {
            flex: 0 0 48%;
            max-width: 48%;
            padding-right: 0;
            @include phone {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        .col-7 {
            flex: 0 0 52%;
            max-width: 52%;
            position: relative;
            @include phone {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0;
            }
        }
        .side-menu-top {
            background: #e1e1e1;
            .side-menu-item {
                padding-top: 45px;
                padding-bottom: 20px;
                padding-left: 30px;
                padding-right: 65px;
                border-bottom: 1px solid #eee;
                @include phone {
                    padding: 20px;
                    padding-top: 35px;
                }
                .title {
                    color: #a4641a;
                    font-size: 18px;
                    font-weight: bold;
                }
                .form {
                    .input-group {
                        box-shadow: -3px -4px 8px #ffffff9c,
                            0px 3px 6px #00000029;
                        border: 1px solid #ffffff;
                        border-radius: 11px;
                        height: 53px;
                        @include phone {
                            height: 45px;
                        }
                        .input-group-text {
                            background: transparent
                                linear-gradient(
                                    180deg,
                                    #ececec 0%,
                                    #f9f9f9 35%,
                                    #ffffff 100%
                                )
                                0% 0% no-repeat padding-box;
                            border: none;
                            border-radius: 11px 0px 0px 11px;
                            padding: 13px;
                            position: relative;
                            font-weight: bold;
                            &:after {
                                content: "";
                                position: absolute;
                                border-right: 1px solid #eee;
                                top: 10px;
                                bottom: 10px;
                                right: 0;
                                z-index: 1;
                            }
                            .select-coupon {
                                border-radius: 11px 0px 0px 11px;
                                padding-right: 30px;
                                height: 100%;
                                color: #0f2a68;
                                background: #f9f9f9
                                    url("~@/assets/tgd/lightbox/box1-arrow1.svg")
                                    no-repeat 90%;
                            }
                        }
                        .coupon-text {
                            color: #0f2a68;
                        }
                        .form-control {
                            background: transparent
                                linear-gradient(
                                    180deg,
                                    #ececec 0%,
                                    #f9f9f9 35%,
                                    #ffffff 100%
                                )
                                0% 0% no-repeat padding-box;
                            border: none;
                            border-radius: 0px 11px 11px 0px;
                            padding: 14px;
                            font: normal normal 600 16px/22px Open Sans;
                        }
                        select {
                            flex: 1;
                            background: #f9f9f9
                                url("~@/assets/tgd/lightbox/select-arrow-box.svg")
                                no-repeat 101%;
                            border: none;
                            border-radius: 11px;
                            padding: 15px;
                            font: normal normal 600 16px/22px Open Sans;
                            appearance: none;
                            filter: contrast(100%);
                            transition: all 0.3s;
                            &:hover {
                                filter: contrast(130%);
                            }
                        }
                    }
                }
            }
        }
        .side-menu-bottom {
            background: transparent
                linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%)
                0% 0% no-repeat padding-box;
            .side-menu-item {
                padding-top: 30px;
                padding-bottom: 30px;
                padding-left: 30px;
                padding-right: 65px;
                border-bottom: 1px solid #eee;
                @include phone {
                    padding: 20px;
                    padding-top: 35px;
                }

                label {
                    input[type="checkbox"] {
                        display: none;
                    }
                    input[type="checkbox"] + span {
                        display: inline-block;
                        padding-left: 38px;
                        line-height: 28px;
                        background: url("~@/assets/tgd/lightbox/radio-none.svg")
                            no-repeat;
                        user-select: none;
                    }
                    input[type="checkbox"]:checked + span {
                        background: url("~@/assets/tgd/lightbox/radio-checked.svg")
                            no-repeat;
                    }
                }
                .col-5 {
                    flex: 0 0 48%;
                    max-width: 48%;
                }
                .col-7 {
                    flex: 0 0 52%;
                    max-width: 52%;
                    position: relative;
                    .cancel-img {
                        position: absolute;
                        right: -40px;
                        top: 50%;
                        transform: translate(0%, -65%);
                        img {
                            filter: contrast(100%);
                            transition: all 0.3s;
                            &:hover {
                                filter: contrast(130%);
                            }
                        }
                    }
                    .wrap {
                        position: relative;
                        @include phone {
                            flex: 0 0 88% !important;
                            max-width: 88% !important;
                        }
                        .cancel-img {
                            transform: translate(0%, -50%);
                        }
                    }
                }
                .title-group {
                    display: flex;
                    align-items: center;
                    label {
                        padding-left: 15px;
                        span {
                            font: normal normal bold 16px/21px Microsoft
                                JhengHei;
                            letter-spacing: 0px;
                            color: #000000;
                            padding-left: 10px;
                        }
                    }
                }
                .title {
                    color: #a4641a;
                    font-size: 18px;
                    font-weight: bold;
                    span {
                        font: normal normal bold 18px/22px Open Sans;
                    }
                }
                form {
                    .input-group {
                        box-shadow: -3px -4px 8px #ffffff9c,
                            0px 3px 6px #00000029;
                        border: 1px solid #ffffff;
                        border-radius: 11px;
                        height: 53px;
                        .input-group-text {
                            background: transparent
                                linear-gradient(
                                    180deg,
                                    #ececec 0%,
                                    #f9f9f9 35%,
                                    #ffffff 100%
                                )
                                0% 0% no-repeat padding-box;
                            border: none;
                            border-radius: 11px 0px 0px 11px;
                            padding: 15px;
                            position: relative;
                            font-weight: bold;
                            &:after {
                                content: "";
                                position: absolute;
                                border-right: 1px solid #eee;
                                top: 10px;
                                bottom: 10px;
                                right: 0;
                                z-index: 1;
                            }
                            .select-coupon {
                                border-radius: 11px 0px 0px 11px;
                                padding-right: 32px;
                                height: 100%;
                                color: #0f2a68;
                                background: #f9f9f9
                                    url("~@/assets/tgd/lightbox/box1-arrow1.svg")
                                    no-repeat 90%;
                            }

                            .select-money {
                                border-radius: 11px 0px 0px 11px;
                                padding-right: 32px;
                                height: 100%;
                                color: #a4641a;
                                background: #f9f9f9
                                    url("~@/assets/tgd/lightbox/box1-arrow2.svg")
                                    no-repeat 90%;
                            }
                        }
                        .coupon-text {
                            color: #0f2a68;
                        }
                        .money-text {
                            color: #a4641a;
                        }
                        .form-control {
                            background: transparent
                                linear-gradient(
                                    180deg,
                                    #ececec 0%,
                                    #f9f9f9 35%,
                                    #ffffff 100%
                                )
                                0% 0% no-repeat padding-box;
                            border: none;
                            border-radius: 0px 11px 11px 0px;
                            padding: 14px;
                            font: normal normal 600 16px/22px Open Sans;
                        }
                        select {
                            flex: 1;
                            background: #f9f9f9
                                url("~@/assets/tgd/lightbox/select-arrow-box.svg")
                                no-repeat 101%;
                            border: none;
                            border-radius: 11px;
                            padding: 15px;
                            font: normal normal 600 16px/22px Open Sans;
                            appearance: none;
                            filter: contrast(100%);
                            transition: all 0.3s;
                            &:hover {
                                filter: contrast(130%);
                            }
                        }
                        .custom-rounded {
                            border-radius: 0px 11px 11px 0px;
                        }
                        .btn {
                            width: 100%;
                            border-radius: 11px;
                            padding: 15px;
                            background: transparent
                                linear-gradient(
                                    282deg,
                                    #ebc496 0%,
                                    #a4641a 36%,
                                    #b57831 88%,
                                    #a4641a 100%
                                );
                            box-shadow: 0px 3px 6px #00000029;
                            color: #fff;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            border: 1px solid #ffffff85;
                        }
                    }
                }
                .phone-btn {
                    @include phone {
                        width: 136px !important;
                        height: 44px !important;
                    }
                    button {
                        @include phone {
                            width: 136px !important;
                            height: 44px !important;
                        }
                    }
                }
                .col-4 {
                    padding-left: 5px;
                    padding-right: 5px;
                    &-less {
                        width: calc( 100% / 3 - 30px);
                    }
                    &-more {
                        width: calc( 100% / 3 + 30px);
                    }
                }
                .btn {
                    width: 100%;
                    border-radius: 11px;
                    padding: 15px;
                    background: transparent
                        linear-gradient(
                            282deg,
                            #ebc496 0%,
                            #a4641a 36%,
                            #b57831 88%,
                            #a4641a 100%
                        );
                    box-shadow: 0px 3px 6px #00000029;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border: 1px solid #ffffff85;
                    span {
                        position: relative;
                        top: 2px;
                    }
                }
                .btn-cancel {
                    height: 70px;
                    border-radius: 11px;
                    padding: 15px;
                    box-shadow: 0px 3px 6px #00000029;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font: normal normal bold 18px/24px Microsoft JhengHei;
                    letter-spacing: 0px;
                    width: 100%;
                    background: #a2a2a2 0% 0% no-repeat padding-box;
                    justify-content: center;
                    @include phone {
                        height: 60px;
                    }
                    span {
                        position: relative;
                        top: 2px;
                    }
                    .price {
                        display: flex;
                        align-items: center;
                        font: normal normal 600 16px/22px Open Sans;
                        strong {
                            font: normal normal bold 29px/39px Open Sans;
                            letter-spacing: 0px;
                            padding-left: 20px;
                        }
                    }
                }
                .btn-checkout {
                    height: 70px;
                    border-radius: 11px;
                    padding: 15px;
                    box-shadow: 0px 3px 6px #00000029;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font: normal normal bold 18px/24px Microsoft JhengHei;
                    letter-spacing: 0px;
                    width: 100%;
                    @include phone {
                        height: 60px;
                    }
                    span {
                        position: relative;
                        top: 2px;
                    }
                    .price {
                        display: flex;
                        align-items: center;
                        font: normal normal 600 16px/22px Open Sans;
                        strong {
                            font: normal normal bold 29px/39px Open Sans;
                            letter-spacing: 0px;
                            padding-left: 20px;
                        }
                    }
                    background: #d94343;
                    justify-content: center;
                }
            }
        }
    }

    :deep(.custom-rounded) {
        flex-wrap: nowrap;

        .input-group-text {
            padding-left: 18px;
            padding-right: 8.8px;
        }

        .aselect {
            padding-left: 10px;
        }
    }

    :deep(.form-control) {
        position: unset;
    }

    :deep(.base-counter) {
        .error-msg {
            position: absolute;
            left: 0;
            top: 100%;
        }
    }
}

.input-group {
    padding: 0;
}
</style>
